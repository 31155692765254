<template>
  <div class="gonggao page-bg-gradient min-h-full">
    <Headers></Headers>
    <div class="pt-130px">
      <div class="content !bg-[#ffffff1a] text-white">
        <div class="ggbox1">{{ content.noticeTittle }}</div>
        <div class="py-4px">{{content.noticeCall}}</div>
        <div v-html="content.noticeContent" style="text-indent:2rem" class="word-wrap"></div>
        <div class="text-right py-4px">
          {{content.noticeSign}}
        </div>
        <div class="text-right py-4px">
          {{content.createTime | formatDate('yyyy-MM-dd HH:mm')}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from "../mixins/index";
import Headers from "./headers.vue";
export default {
  data() {
    return {
      id: "",
      content: {},
    };
  },
  mixins:[mixin],
  mounted() {
    this.id = this.$route.query.id;
    this.$axios.get(`${this.gonggao}/${this.$i18n.locale}/${this.id}`).then((res) => {
      this.content = res.data.data;
    });
  },
  components: {
    Headers,
  },
};
</script>

<style lang="scss">
.text-right{
  text-align: right;
}
.py-4px{
  padding: 4px 0;
}
.gonggao {
  @apply text-primary;
  .content {
    background: #ffffff;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 15px 10px;
    .ggbox1 {
      text-align: center;
      font-size: 20px;
      margin-bottom: 15px;
    }
  }
}
</style>